import { useQuery } from "@tanstack/react-query";
import type {
    ModuleCarouselFieldsFragment,
    ModuleCarouselQueryVariables,
} from "@/components/contentful/module-carousel/__generated/module-carousel.contentful.generated";
import { ModuleCarouselDocument } from "@/components/contentful/module-carousel/__generated/module-carousel.contentful.generated";
import { customFetcher } from "@/lib/contentful/fetch-config";
import type {
    AllPageArticleCollectionByContentTagQuery,
    AllPageArticleCollectionByContentTagQueryVariables,
} from "@/components/contentful/page-article/__generated/page-article.contentful.generated";
import { AllPageArticleCollectionByContentTagDocument } from "@/components/contentful/page-article/__generated/page-article.contentful.generated";
import type { CoreGraphqlEntryProps } from "@/types/page";

type ModuleCarouselQuery = {
    __typename?: "Query";
    moduleCarousel?:
        | ({ __typename?: "ModuleCarousel" } & ModuleCarouselFieldsFragment & {
                  pages: AllPageArticleCollectionByContentTagQuery["pages"] | null;
              })
        | null;
};

const fetchCarouselAndArticles = async ({
    queryKey,
}: {
    queryKey: [string, ModuleCarouselQueryVariables];
}): Promise<ModuleCarouselQuery> => {
    const [, { id, locale, preview }] = queryKey;

    const moduleData = await customFetcher<ModuleCarouselQuery, ModuleCarouselQueryVariables>(
        ModuleCarouselDocument,
        { id, locale, preview }
    )();

    let tags: string[] | undefined;

    if (moduleData.moduleCarousel?.contentTagsCollection?.items) {
        tags = moduleData.moduleCarousel.contentTagsCollection.items
            .map((tag) => tag?.tagKey)
            .filter((tag): tag is string => tag !== null && tag !== undefined);
    }

    // Fetch articles using the filtered tags
    const articlesData = await customFetcher<
        AllPageArticleCollectionByContentTagQuery,
        AllPageArticleCollectionByContentTagQueryVariables
    >(AllPageArticleCollectionByContentTagDocument, {
        tags,
        locale,
        preview,
        limit: 10,
    })();

    return {
        __typename: "Query",
        moduleCarousel: {
            __typename: "ModuleCarousel",
            sys: moduleData.moduleCarousel!.sys,
            ...moduleData.moduleCarousel,
            pages:
                tags && tags.length > 0 && articlesData.pages && articlesData.pages.items.length > 0
                    ? articlesData.pages
                    : null,
        },
    };
};

export const useCustomModuleCarouselQuery = ({ id, locale, preview }: CoreGraphqlEntryProps) => {
    return useQuery({
        queryKey: ["ModuleCarousel", { id, locale, preview }],
        queryFn: () =>
            fetchCarouselAndArticles({
                queryKey: ["ModuleCarousel", { id, locale, preview }],
            }),
        ...(!preview && { staleTime: Infinity }),
        refetchOnWindowFocus: false,
    });
};

useCustomModuleCarouselQuery.getKey = (variables: ModuleCarouselQueryVariables) => [
    "ModuleCarousel",
    variables,
];

useCustomModuleCarouselQuery.fetcher = (variables: ModuleCarouselQueryVariables) => () =>
    fetchCarouselAndArticles({ queryKey: ["ModuleCarousel", variables] });
