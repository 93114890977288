import * as Types from "../../../../lib/contentful/__generated/graphql.types";

import {
    ComponentReferenceFields_Car_Fragment,
    ComponentReferenceFields_ContentTag_Fragment,
    ComponentReferenceFields_Driver_Fragment,
    ComponentReferenceFields_ExternalLink_Fragment,
    ComponentReferenceFields_Footer_Fragment,
    ComponentReferenceFields_LanguageSelectorItem_Fragment,
    ComponentReferenceFields_MainNavigation_Fragment,
    ComponentReferenceFields_Microcopy_Fragment,
    ComponentReferenceFields_MicrocopySet_Fragment,
    ComponentReferenceFields_ModalLink_Fragment,
    ComponentReferenceFields_ModuleAccordion_Fragment,
    ComponentReferenceFields_ModuleAudioPlayer_Fragment,
    ComponentReferenceFields_ModuleCarTechSpecs_Fragment,
    ComponentReferenceFields_ModuleCarousel_Fragment,
    ComponentReferenceFields_ModuleCircuit_Fragment,
    ComponentReferenceFields_ModuleDriverList_Fragment,
    ComponentReferenceFields_ModuleGallery_Fragment,
    ComponentReferenceFields_ModuleIframe_Fragment,
    ComponentReferenceFields_ModuleImage_Fragment,
    ComponentReferenceFields_ModuleListenToTheEngine_Fragment,
    ComponentReferenceFields_ModuleMediaFeature_Fragment,
    ComponentReferenceFields_ModulePageLinkTile_Fragment,
    ComponentReferenceFields_ModuleQuote_Fragment,
    ComponentReferenceFields_ModuleRichText_Fragment,
    ComponentReferenceFields_ModuleSideBySide_Fragment,
    ComponentReferenceFields_ModuleSpacer_Fragment,
    ComponentReferenceFields_ModuleSplitLayout_Fragment,
    ComponentReferenceFields_ModuleTable_Fragment,
    ComponentReferenceFields_ModuleVideo_Fragment,
    ComponentReferenceFields_Navigation_Fragment,
    ComponentReferenceFields_PageArticle_Fragment,
    ComponentReferenceFields_PageBasic_Fragment,
    ComponentReferenceFields_PageCar_Fragment,
    ComponentReferenceFields_PageCategory_Fragment,
    ComponentReferenceFields_PageDriver_Fragment,
    ComponentReferenceFields_PageHomepage_Fragment,
    ComponentReferenceFields_PageRaceEvent_Fragment,
    ComponentReferenceFields_PageRaceSeries_Fragment,
    ComponentReferenceFields_PageSearch_Fragment,
    ComponentReferenceFields_PageTeam_Fragment,
    ComponentReferenceFields_PartAccordionItem_Fragment,
    ComponentReferenceFields_PartCircuitHotspot_Fragment,
    ComponentReferenceFields_PartCircuitTimelineItem_Fragment,
    ComponentReferenceFields_PartMainNavigationAccordion_Fragment,
    ComponentReferenceFields_PartMainNavigationItem_Fragment,
    ComponentReferenceFields_Partner_Fragment,
    ComponentReferenceFields_PartnerSet_Fragment,
    ComponentReferenceFields_SeoMetadata_Fragment,
    ComponentReferenceFields_SiteSettings_Fragment,
    ComponentReferenceFields_Team_Fragment,
} from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import { ContentTagFieldsFragment } from "../../../../lib/contentful/content-tag/__generated/content-tag.contentful.generated";
import { PageArticleLinkToFieldsFragment } from "../../page-article/__generated/page-article.contentful.generated";
import { ModuleImageFieldsFragment } from "../../module-image/__generated/module-image.contentful.generated";
import { PageDriverLinkToFieldsFragment } from "../../page-driver/__generated/page-driver.contentful.generated";
import { PageBasicLinkToFieldsFragment } from "../../page-basic/__generated/page-basic.contentful.generated";
import { ComponentReferenceFieldsFragmentDoc } from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import { ContentTagFieldsFragmentDoc } from "../../../../lib/contentful/content-tag/__generated/content-tag.contentful.generated";
import { PageArticleLinkToFieldsFragmentDoc } from "../../page-article/__generated/page-article.contentful.generated";
import { ModuleImageFieldsFragmentDoc } from "../../module-image/__generated/module-image.contentful.generated";
import { PageDriverLinkToFieldsFragmentDoc } from "../../page-driver/__generated/page-driver.contentful.generated";
import { PageBasicLinkToFieldsFragmentDoc } from "../../page-basic/__generated/page-basic.contentful.generated";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { customFetcher } from "@/lib/contentful/fetch-config";
export type ModuleCarouselFieldsFragment = {
    __typename?: "ModuleCarousel";
    title?: string | null;
    description?: string | null;
    imageCarousel?: boolean | null;
    imageDescriptionPlacement?: string | null;
    contentTagsCollection?: {
        __typename?: "ModuleCarouselContentTagsCollection";
        items: Array<({ __typename?: "ContentTag" } & ContentTagFieldsFragment) | null>;
    } | null;
    carouselItemsCollection?: {
        __typename?: "ModuleCarouselCarouselItemsCollection";
        items: Array<
            | ({ __typename?: "ModuleImage" } & ModuleImageFieldsFragment)
            | ({ __typename?: "PageArticle" } & PageArticleLinkToFieldsFragment)
            | ({ __typename?: "PageBasic" } & PageBasicLinkToFieldsFragment)
            | ({ __typename?: "PageDriver" } & PageDriverLinkToFieldsFragment)
            | null
        >;
    } | null;
} & ComponentReferenceFields_ModuleCarousel_Fragment;

export type ModuleCarouselQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview: Types.Scalars["Boolean"]["input"];
    id: Types.Scalars["String"]["input"];
}>;

export type ModuleCarouselQuery = {
    __typename?: "Query";
    moduleCarousel?: ({ __typename?: "ModuleCarousel" } & ModuleCarouselFieldsFragment) | null;
};

export const ModuleCarouselFieldsFragmentDoc = `
    fragment ModuleCarouselFields on ModuleCarousel {
  ...ComponentReferenceFields
  title
  description
  imageCarousel
  imageDescriptionPlacement
  contentTagsCollection {
    items {
      ... on ContentTag {
        ...ContentTagFields
      }
    }
  }
  carouselItemsCollection {
    items {
      ... on PageArticle {
        ...PageArticleLinkToFields
      }
      ... on ModuleImage {
        ...ModuleImageFields
      }
      ... on PageDriver {
        ...PageDriverLinkToFields
      }
      ... on PageBasic {
        ...PageBasicLinkToFields
      }
    }
  }
}
    `;
export const ModuleCarouselDocument = `
    query ModuleCarousel($locale: String!, $preview: Boolean!, $id: String!) {
  moduleCarousel(id: $id, locale: $locale, preview: $preview) {
    ...ModuleCarouselFields
  }
}
    ${ModuleCarouselFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}
${ContentTagFieldsFragmentDoc}
${PageArticleLinkToFieldsFragmentDoc}
${ModuleImageFieldsFragmentDoc}
${PageDriverLinkToFieldsFragmentDoc}
${PageBasicLinkToFieldsFragmentDoc}`;

export const useModuleCarouselQuery = <TData = ModuleCarouselQuery, TError = unknown>(
    variables: ModuleCarouselQueryVariables,
    options?: Omit<UseQueryOptions<ModuleCarouselQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<ModuleCarouselQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<ModuleCarouselQuery, TError, TData>({
        queryKey: ["ModuleCarousel", variables],
        queryFn: customFetcher<ModuleCarouselQuery, ModuleCarouselQueryVariables>(
            ModuleCarouselDocument,
            variables
        ),
        ...options,
    });
};

useModuleCarouselQuery.getKey = (variables: ModuleCarouselQueryVariables) => [
    "ModuleCarousel",
    variables,
];

useModuleCarouselQuery.fetcher = (
    variables: ModuleCarouselQueryVariables,
    options?: RequestInit["headers"]
) =>
    customFetcher<ModuleCarouselQuery, ModuleCarouselQueryVariables>(
        ModuleCarouselDocument,
        variables,
        options
    );
